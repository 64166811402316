import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaginatedList = ({
  setPaginatedList,
  searchInput,
  setShowSpinner,
  endPoint,
  endPoint2,
  limit = 100,
  setPurchasePaginatedList,
  purchaseLimit,
  refresh,
}) => {
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  // const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  // const [limit] = useState(100);

  // currentPage, pageSize
  const fetchData = async (page, startDate, endDate) => {
    setShowSpinner(true); //inventory-page
    try {
      if (endPoint) {
        const response = await axios.get(
          `${process.env.REACT_APP_CRI_API}${endPoint}`,
          {
            params: {
              branch: userData.branch,
              invoice_id: "",
              page,
              limit,
              searchInput,
              startDate,
              endDate,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
        setPaginatedList(response.data.items);
        // setData(response.data.items);
        setTotalPages(response.data.totalPages);
      }

      // Fetch purchase invoices

      if (endPoint2) {
        const response = await axios.get(
          `${process.env.REACT_APP_CRI_API}${endPoint2}`,
          {
            params: {
              branch: userData.branch,
              invoice_id: "",
              page,
              limit: purchaseLimit,
              searchInput,
              startDate,
              endDate,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
        if (!endPoint) {
          // console.log(response.data.totalPages);
          setTotalPages(response.data.totalPages);
        }
        setPurchasePaginatedList(response.data.items);
      }
      setShowSpinner(false);
    } catch (err) {
      console.error("Error fetching data:", err);

      setShowSpinner(false);
      if (
        err.response &&
        err.response.data &&
        (err.response.data.err?.name === "TokenExpiredError" ||
          err.response.data.message === "Unauthorized")
      ) {
        // alert("Login Again to Continue");
        navigate("/");

        sessionStorage.removeItem("token");
        sessionStorage.removeItem("srchData");
        sessionStorage.removeItem("prescriptionHistory");
        sessionStorage.removeItem("rights");
        navigate("/cri-crm");
      }
      return;
    }
  };
  // const fetchHandler = () => {
  //   // Get the current year and month dynamically
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   const currentMonth = currentDate.getMonth(); // 0 = January, so currentMonth will range from 0 to 11
  //
  //   // Calculate how many months to go back based on the page number
  //   const monthsToGoBack = currentPage - 1;
  //   const targetMonth = currentMonth - monthsToGoBack;
  //   const targetYear = currentYear - Math.floor(Math.abs(targetMonth) / 12);
  //
  //   // Correct the month if it goes negative
  //   const calculatedMonth = ((targetMonth % 12) + 12) % 12;
  //
  //   // Create the start and end dates for the month
  //   const startDate = new Date(targetYear, calculatedMonth, 1);
  //   const endDate = new Date(targetYear, calculatedMonth + 1, 1); // Exclusive of the next month
  //   fetchData(currentPage, startDate, endDate);
  // };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchInput, refresh]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      {/* Pagination Controls using Reactstrap */}
      <Pagination aria-label="Page navigation example">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => handlePageChange(1)} />
        </PaginationItem>

        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePageChange(currentPage - 1)}
          />
        </PaginationItem>

        {[...Array(totalPages)].map((_, index) => (
          <PaginationItem key={index} active={index + 1 === currentPage}>
            <PaginationLink onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </PaginationItem>

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => handlePageChange(totalPages)} />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginatedList;
