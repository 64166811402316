// PrintBill.js
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row, Table } from "reactstrap";
import ci from "../../../../images/cutis_new.jpeg";
import { ReactComponent as Rupees } from "../../../../images/currency-rupee.svg";
import { numberToWords } from "../../CRM-Calicut/utils/numberWords";

const PrintBill = React.forwardRef(
  (
    {
      data,
      address,
      invoice_id,
      date,
      invoiceSheet,
      handleInputChange,
      description,
      total,
      payment_mode,
      setPayment_mode,
      submitHandler,
      navigate,
      branch,
      buttonHide,
      tax,
      netAmountPay,
      GSTIN,
      discount,
      receivedAmt,
      balanceAmount,
      totalQTY,
      totalTax,
      taxableData,
      formData,
    },
    ref
  ) => {
    // const [printVisible, setPrintVisible] = useState(false);
    const options = {
      hour: "numeric",
      minute: "numeric",
      //  second: "numeric",
      timeZone: "Asia/Kolkata", // Set the desired time zone (Indian Standard Time)
    };
    const printDate = (
      <span style={{ fontSize: "10px" }}>
        {`${new Date(date).getDate()} / ${
          new Date(date).getMonth() + 1
        } /  ${new Date(date).getFullYear()} `}{" "}
        {
          //new Date(date).toLocaleTimeString("en-US", options)
        }
      </span>
    );

    const formatDate = (date) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    //  useEffect(() => {
    //    if (buttonHide.display === "none") {
    //      setPrintVisible(true);
    //    }
    //  }, [buttonHide]);

    function formatCurrency(amount) {
      return amount.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      });
    }

    return (
      <Container className="p-3 pt-2">
        <div
          ref={ref}
          // style={{ display: printVisible ? "block" : "none" }}
          style={{ border: "solid thin black", margin: "40px" }}
        >
          <Row
            className="d-flex justify-content-start mx-0"
            style={{
              borderBottom: "1px solid black",
            }}
          >
            <Col
              md={4}
              sm={3}
              xs={4}
              className="d-flex flex-column align-items-start px-0 mt-2"
            >
              <div className="d-flex align-items-center">
                {branch && branch === "CLT" && (
                  <img
                    src={ci}
                    alt="Clinic Logo"
                    className="img-fluid"
                    style={{
                      maxWidth: "100%",
                      opacity: "60%",
                    }}
                  />
                )}
              </div>

              <div
                className="px-2 ps-5"
                style={{ fontSize: "9px", textAlign: "left" }}
              >
                <b>GSTIN:</b> {GSTIN}
                <br />
                <b>Mobile:</b> +91 8137-900741
              </div>
            </Col>

            <Col
              md={5}
              sm={5}
              xs={5}
              className="pt-2 pe-1 pb-2"
              style={{
                fontSize: "9px",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  color: "rgba(139, 15, 122)",
                  fontSize: "12px",
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                <b>{address.first_line}</b>
              </span>
              <span style={{ fontSize: "9px" }}>{address.second_line}</span>
              <br />
              <span style={{ fontSize: "9px" }}>
                {address.third_line}, {address.fourth_line}
              </span>
            </Col>

            <Col
              md={3}
              sm={3}
              xs={3}
              className="pt-3 ms-0"
              style={{
                fontSize: "9px",

                overflow: "hidden",
                textAlign: "left",
              }}
            >
              <b>TAX INVOICE</b>
              <div
                className=" d-inline-block"
                style={{
                  display: "inline-block",
                  border: "1px solid grey",
                  color: "grey",
                  marginTop: "5px",
                }}
              >
                ORIGINAL FOR RECIPIENT
              </div>
              <br />
              Invoice No: {invoice_id}
              <br />
              <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                Date: {printDate}
              </div>
            </Col>
          </Row>

          <Row
            className="pt-3 text-md ps-5 d-flex justify-content-between"
            style={{ fontSize: "11px" }}
          >
            <Col sm={5} xs={5} style={{ textAlign: "left" }}>
              <b>BILL TO:</b>{" "}
              {(data && data.otData.fname) || formData.patientName}
              <br />
              Contact No:{" "}
              {(data && data.otData.contactno) || formData.contactNo}
            </Col>

            <Col sm={4} xs={4} style={{ textAlign: "left" }}>
              <b>SHIP TO:</b>{" "}
              {(data && data.otData.fname) || formData.patientName}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center ">
            <Col>
              <Table>
                <thead>
                  <tr
                    style={{
                      fontSize: "11px",

                      //  opacity: "70%",
                    }}
                  >
                    <th
                      style={{
                        width: "8%",
                        textAlign: "center",

                        //  border: "none",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      SL No.
                    </th>
                    <th
                      style={{
                        width: "40%",
                        textAlign: "center",
                        // border: "none",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      ITEM/SERVICES
                    </th>
                    <th
                      style={{
                        width: "10%",
                        textAlign: "center",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        // border: "none",
                        //color: "white",
                        borderRight: "solid thin black",
                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      QTY{" "}
                    </th>

                    <th
                      style={{
                        width: "15%",
                        // border: "none",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      RATE
                    </th>

                    <th
                      style={{
                        width: "10%",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      TAX
                    </th>
                    <th
                      style={{
                        width: "17%",
                        border: "none",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                        textAlign: "center",
                      }}
                    >
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody style={{ minHeight: "600px" }}>
                  {invoiceSheet &&
                    invoiceSheet.map((row, index) => {
                      const taxper = (
                        parseFloat(row[`Row${index + 1}`].cgst) +
                        parseFloat(row[`Row${index + 1}`].sgst)
                      ).toFixed(2);
                      return (
                        <tr
                          key={index}
                          style={{
                            fontSize: "11px",
                            borderTop: "none",
                            borderBottom: "none",
                          }}
                        >
                          <td
                            style={{
                              borderRight: "solid thin black",
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "2px",
                              // margin: "0px",
                            }}
                          >
                            {row[`Row${index + 1}`].item && index + 1}{" "}
                          </td>
                          <td
                            style={{
                              borderRight: "solid thin black",
                              textAlign: "left",
                              borderTop: "none",
                              borderBottom: "none",

                              padding: "2px",
                              margin: "0px",
                            }}
                          >
                            {row[`Row${index + 1}`].item}
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "solid thin black",
                              borderTop: "none",
                              borderBottom: "none",

                              padding: "2px",
                              margin: "0px",
                            }}
                          >
                            {" "}
                            {row[`Row${index + 1}`].qty
                              ? row[`Row${index + 1}`].qty
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderRight: "solid thin black",
                              borderTop: "none",
                              borderBottom: "none",

                              padding: "2px",
                              margin: "0px",
                            }}
                          >
                            {row[`Row${index + 1}`].rate
                              ? row[`Row${index + 1}`].rate
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderRight: "solid thin black",
                              padding: "2px",
                              borderTop: "none",
                              borderBottom: "none",
                            }}
                          >
                            {row[`Row${index + 1}`].tax ? (
                              <span>
                                <div>{row[`Row${index + 1}`].tax}</div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "-2px",
                                  }}
                                >
                                  ({taxper}%)
                                </div>
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderTop: "none",
                              borderBottom: "none",

                              padding: "2px",
                              margin: "0px",
                            }}
                          >
                            {row[`Row${index + 1}`].amount
                              ? row[`Row${index + 1}`].amount
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                  <tr
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    <td
                      style={{
                        //color: "white",
                        border: "none",
                        // borderRight: "solid thin black",
                        backgroundColor: "#D8D6D6",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",

                        padding: "0px",
                        margin: "0px",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "end",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        //color: "white",
                        borderRight: "solid thin black",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      TOTAL
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        borderRight: "solid thin black",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      {totalQTY}
                    </td>
                    <td
                      style={{
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        borderRight: "solid thin black",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "center",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        borderRight: "solid thin black",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      {" "}
                      {totalTax}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        //color: "white",

                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      {" "}
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {formatCurrency(total)}
                    </td>
                  </tr>

                  <tr
                    scope="row"
                    style={{
                      fontSize: "11px",
                      borderBottom: "solid thin black",
                    }}
                  >
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        borderRight: "solid thin black",
                      }}
                    >
                      Discount
                    </td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {discount}
                    </td>
                  </tr>
                  <tr
                    scope="row"
                    style={{
                      fontSize: "11px",
                      borderBottom: "solid thin black",
                    }}
                  >
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        borderRight: "solid thin black",
                      }}
                    >
                      Net Amount Payable
                    </td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {formatCurrency(netAmountPay)}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    >
                      Received Amount
                    </td>
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>{" "}
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>{" "}
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "right",
                        borderBottom: "solid thin black",
                      }}
                    >
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {receivedAmt !== NaN ? receivedAmt : ""}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    >
                      Balance Amount
                    </td>
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "right",
                        borderBottom: "solid thin black",
                      }}
                    >
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {balanceAmount}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mt-0">
            <Col>
              <Table>
                <thead>
                  <tr
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    <th
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        //color: "white",
                        border: "none",
                        backgroundColor: "#D8D6D6",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      HSN/SAC
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        // border: "none",
                        borderRight: "solid thin black",

                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      Taxable Value
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderRight: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      CGST
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        //color: "white",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderRight: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      SGST
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        //color: "white",

                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      Total Tax Amount
                    </th>
                  </tr>
                  <tr style={{ fontSize: "11px" }}>
                    <td
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        // color: "white",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderTop: "solid thin black",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    >
                      Rate
                    </td>
                    <td
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        // color: "white",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderRight: "solid thin black",
                        borderTop: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    >
                      Amount
                    </td>
                    <td
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        // color: "white",
                        backgroundColor: "#D8D6D6",
                        borderRight: "solid thin black",
                        borderBottom: "solid thin black",
                        borderTop: "solid thin black",
                      }}
                    >
                      Rate
                    </td>
                    <td
                      style={{
                        padding: "1px",
                        textAlign: "center",
                        //  color: "white",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                        borderRight: "solid thin black",
                        borderTop: "solid thin black",
                        borderBottom: "solid thin black",
                      }}
                    >
                      Amount
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontSize: "11px",
                      borderBottom: "solid thin black",
                      borderTop: "solid thin black",
                    }}
                  >
                    <td style={{ borderRight: "solid thin black" }}></td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          return (
                            <span key={`${id} tot`}>
                              {(
                                parseFloat(item.rate) * parseFloat(item.qty)
                              ).toFixed(2)}
                              <br />
                            </span>
                          );
                        })}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          return (
                            <>
                              {parseFloat(item.cgst) + "%"}
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          const perce = (
                            (parseFloat(item.rate) *
                              parseFloat(item.qty) *
                              parseFloat(item.cgst)) /
                            100
                          ).toFixed(2);
                          return (
                            <>
                              {perce}
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          return (
                            <>
                              {parseFloat(item.sgst) + "%"}
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          const perce = (
                            (parseFloat(item.rate) *
                              parseFloat(item.qty) *
                              parseFloat(item.sgst)) /
                            100
                          ).toFixed(2);
                          return (
                            <>
                              {perce}
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        // borderRight: "solid thin black",
                      }}
                    >
                      {taxableData &&
                        taxableData.map((item, id) => {
                          return (
                            <>
                              <Rupees
                                style={{ maxWidth: "11px", maxHeight: "11px" }}
                              />{" "}
                              {item.tax}
                              <br />
                            </>
                          );
                        })}
                    </td>
                  </tr>
                </thead>
              </Table>
            </Col>
          </Row>
          <Row className="px-3">
            <Col sm={3} style={{ maxWidth: "150px", fontSize: "11px" }}>
              Payment Mode - {payment_mode}
            </Col>
            <Col md="3" sm={2} style={{ fontSize: "11px" }}></Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col>
              <Table bordered>
                <tbody>
                  <tr style={{ fontSize: "11px", textAlign: "left" }}>
                    <td
                      colSpan={2}
                      style={{
                        borderBottom: "solid thin #D8D6D6",
                        borderTop: "solid thin #D8D6D6",
                      }}
                    >
                      <b>
                        Total Amount (in Words) - {numberToWords(receivedAmt)}{" "}
                        Rupees
                      </b>
                    </td>
                  </tr>
                  <tr style={{ fontSize: "11px" }}>
                    <td
                      width={"50%"}
                      style={{
                        borderBottom: "solid thin #D8D6D6",
                        borderTop: "solid thin #D8D6D6",
                      }}
                    >
                      <b>Notes</b>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "bottom",
                        height: "70px",
                        fontSize: "11px",

                        borderBottom: "solid thin #D8D6D6",
                        borderTop: "solid thin #D8D6D6",
                      }}
                    >
                      Authorised Signatory For <br />
                      Cutis International Cosmetic Clinics P Ltd
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="px-3">
            <Col style={{ fontSize: "11px", textAlign: "left" }}>
              This is a computer-generated invoice. No signature is required.
            </Col>
            <Col style={{ fontSize: "11px" }} md={3} sm={3}>
              Billed By: {data && data.otData.createdBy}
            </Col>
          </Row>
          <Row className="px-3">
            <Col
              className="mt-3"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <p>
                <b>Terms and Conditions</b>
              </p>
              <p className="bill-terms">
                1. Goods once sold will not be taken back or exchanged
              </p>
              <p className="mt-0">
                2. All disputes are subject to Calicut jurisdiction only
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
);

export default PrintBill;
