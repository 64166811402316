import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getbranchData } from "../../../../redux/authentication";
import axios from "axios";
import {
  getInventoryItems,
  getInventoryPurchase,
  getInventorySupplier,
} from "../../../../redux/inventory";
import { useNavigate } from "react-router-dom";

const InventoryForm = ({
  editFormData,
  formDataPurchase,
  setInventoryFormData,
  rowIndex,
  toggle,
}) => {
  const navigate = useNavigate();
  const itemsList = useSelector((state) => state.itemsList);
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData } = auth;
  const { supplierData, purchaseData, itemsData } = itemsList;

  const [formData, setFormData] = useState({
    ...formDataPurchase,
    //  brandName: "",
    //  batch: "qwqw",
    //  expiry: "",
    //  manufacturer: "",
    //  location: "",
    //  category: "",
    //  minStockLevel: "",
    //  maxStockLevel: "",
    //  reorderPoint: "",
    //  reorderQuantity: "",
    //  barcode: "",
    //  notes: "",
    //  createdBy: userData.user,
  });

  const [matchBatchExpiry, setMatchBatchExpiry] = useState("");

  function getItemByBatchAndExpiry(enteredBatch, enteredExpiryDate) {
    if (enteredBatch && enteredExpiryDate) {
      // Convert enteredBatch to lowercase for case-insensitive comparison
      const enteredBatchLowercase = enteredBatch?.toLowerCase();

      // Iterate over each item in itemsData
      for (const item of itemsData) {
        // Convert item.batch to lowercase for case-insensitive comparison
        const itemBatchLowercase = item?.batch?.toLowerCase();

        // Check if the batch and expiry date match the entered values (case-insensitive)
        if (
          itemBatchLowercase === enteredBatchLowercase &&
          formDataPurchase.item === item.item
        ) {
          // Batch and expiry date match, return the item
          setFormData({ ...formData, expiry: item.expiry });
          return item;
        }
      }

      //  for (const item of itemsData) {
      //    // Convert item.batch to lowercase for case-insensitive comparison
      //    const itemBatchLowercase = item?.batch?.toLowerCase();

      //    // Check if the batch and expiry date match the entered values (case-insensitive)
      //    if (
      //      itemBatchLowercase === enteredBatchLowercase &&
      //      item.expiry === enteredExpiryDate &&
      //      formDataPurchase.item === item.item
      //    ) {
      //      // Batch and expiry date match, return the item
      //      return item;
      //    }
      //  }
      // or check batch exist with different date
      //   for (const item of itemsData) {
      //     // Convert item.batch to lowercase for case-insensitive comparison
      //     const itemBatchLowercase = item?.batch?.toLowerCase();
      //
      //     // Check if the batch and expiry date match the entered values (case-insensitive)
      //     if (itemBatchLowercase === enteredBatchLowercase) {
      //       // Batch and expiry date match, return the item
      //       return item;
      //     }
      //   }
      //   // No matching batch and expiry date found, return null
      //   return null;
    }
  }

  function isValidDate(dateString) {
    // Try to create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid and the input string was successfully parsed
    return !isNaN(date.getTime());
  }
  const validateForm = () => {
    let isValid = true;
    if (!formData.batch) {
      isValid = false;
      // Handle validation error for item field
    }

    if (!isValidDate(formData.expiry)) {
      isValid = false;
    }

    // Add more validation logic for other fields

    return isValid;
  };

  const clearForm = () => {
    setFormData({
      item: "",
      brandName: "",
      unitPrice: "",
      tax: "",
      mrp: "",
      batch: "",
      expiry: "",
      hsn: "",
      manufacturer: "",
      quantity: "",
      location: "",
      category: "",
      minStockLevel: "",
      maxStockLevel: "",
      reorderPoint: "",
      reorderQuantity: "",
      supplierId: "",
      barcode: "",
      notes: "",
      consumable: false,
    });
  };

  useEffect(() => {
    if (editFormData && editFormData.invoice_id) {
      setFormData(editFormData);
      // should change by param invoice_id
      dispatch(
        getInventoryPurchase({
          token: userData.token,
          branch: userData.branch,
          invoice_id: editFormData.invoice_id,
        })
      );
    } else {
      // setFormData(editFormData);
    }
  }, [editFormData]);

  useEffect(() => {
    if (formData?.batch && formData?.expiry) {
      console.log(getItemByBatchAndExpiry(formData.batch, formData.expiry));
      setMatchBatchExpiry(() =>
        getItemByBatchAndExpiry(formData.batch, formData.expiry)
      );
    }
  }, [formData.batch, formData.expiry]);

  const handleChange = (e) => {
    //   const { name, value } = e.target;
    const { name, type, checked, value } = e.target;
    if (type === "date") {
      if (matchBatchExpiry) {
        alert("Please enter New Batch for New Date");
        return;
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    //  setFormData({
    //    ...formData,
    //    [name]: type === 'checkbox' ? checked : value,
    //  });
  };

  useEffect(() => {
    // console.log(userData.token);
    if (userData.token) {
      dispatch(
        getbranchData({ token: userData.token, branch: userData.branch })
      );
      dispatch(
        getInventorySupplier({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  const submitPurchaseHandler = () => {
    // //const valid = formValid();
    // console.log(formDataPurchase);
    // if (
    //   formDataPurchase.paymentDetails.invoice_id &&
    //   !formDataPurchase.purchaseId
    // ) {
    //   // for adding quantity
    //   const postPurchaseData = {
    //     ...formDataPurchase,
    //     paymentDetails: {
    //       ...formDataPurchase.paymentDetails,
    //       quantity: formData.quantity,
    //     },
    //   };
    //   axios
    //     .post(
    //       `${process.env.REACT_APP_CRI_API}add-purchase`,
    //       postPurchaseData,
    //       {
    //         params: {
    //           branch: userData.branch,
    //         },
    //         headers: {
    //           Authorization: `Bearer ${userData.token}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       if (res.status === 200) {
    //         // savedToggle = true;
    //         // stockHandler();
    //         dispatch(
    //           getInventoryPurchase({
    //             token: userData.token,
    //             branch: userData.branch,
    //             invoice_id: formDataPurchase.paymentDetails.invoice_id,
    //           })
    //         );
    //       }
    //     })
    //     .catch((err) => {
    //       if (
    //         (err.response &&
    //           err.response.data &&
    //           err.response.data.err.name === "TokenExpiredError") ||
    //         err.response.data.message === "Unauthorized"
    //       ) {
    //         // navigate("/");
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("srchData");
    //         sessionStorage.removeItem("prescriptionHistory");
    //         sessionStorage.removeItem("rights");
    //         // navigate("/cri-crm");
    //         return err;
    //       }
    //       console.log(err);
    //       return;
    //     });
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && editFormData) {
      editSubmitHandler();
      return;
    }

    if (validateForm()) {
      console.log(formDataPurchase.batch);
      console.log(matchBatchExpiry);
      if (formDataPurchase.batch) {
        if (
          matchBatchExpiry &&
          //   matchBatchExpiry.batch.toLowerCase() ===
          //   formData.batch.toLowerCase() &&
          matchBatchExpiry.expiry === formData.expiry
        ) {
          alert("Continue & Save Purchase - match ");
          toggle();
          setInventoryFormData({
            ...formData,
            productId: matchBatchExpiry.productId,
            stockQty: matchBatchExpiry.quantity,
          });
          return;
        } else {
          alert("Continue & Save Purchase with New Batch");
          toggle();
          setInventoryFormData({
            ...formData,
          });
          // alert("Enter new batch for new expiry date");
          return;
        }
      } else {
        alert("Continue & Save Purchase");
        toggle();
        setInventoryFormData({
          ...formData,
        });
      }
    } else {
      alert("Invalid Form");
      return;
    }
  };

  const editPurchaseHandler = () => {
    if (formDataPurchase.purchaseId) {
      const postPurchaseData = {
        ...formDataPurchase,
        paymentDetails: {
          ...formDataPurchase.paymentDetails,
          quantity: formData.quantity,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_CRI_API}edit-purchase`,
          postPurchaseData,
          {
            params: {
              branch: userData.branch,
              purchaseId: formDataPurchase.purchaseId,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            dispatch(
              getInventoryItems({
                token: userData.token,
                branch: userData.branch,
              })
            );
            alert("Edit success");

            // clearForm();
          }
        })
        .catch((err) => console.log(err));
    } else {
      submitPurchaseHandler();
    }
  };

  const editSubmitHandler = () => {
    axios
      .post(`${process.env.REACT_APP_CRI_API}edit-inventory`, formData, {
        params: {
          branch: userData.branch,
          productId: formData.productId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        //   console.log(res);
        if (res.status === 200) {
          dispatch(
            getInventoryItems({
              token: userData.token,
              branch: userData.branch,
            })
          );
          alert("Edit success");
          editPurchaseHandler();
          clearForm();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container fluid>
      {
        // form here
      }
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="batch">Batch</Label>
              <Input
                type="text"
                name="batch"
                id="batch"
                value={formData.batch}
                onChange={handleChange}
                placeholder="Enter batch"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="expiry">Expiry</Label>
              <Input
                type="date"
                name="expiry"
                id="expiry"
                value={formData.expiry}
                onChange={handleChange}
                placeholder="Enter expiry date"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="category">Category</Label>
              <Input
                type="select"
                name="category"
                id="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Enter category"
              >
                {formData.category ? null : (
                  <option value="">Select option</option>
                )}
                <option value="skin">Skin</option>
                <option value="hair">Hair</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="brandname">Brandname</Label>
              <Input
                type="text"
                name="brandName"
                id="brandname"
                value={formData.brandName}
                onChange={handleChange}
                placeholder="Enter brand name"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="manufacturer">Manufacturer</Label>
              <Input
                type="text"
                name="manufacturer"
                id="manufacturer"
                value={formData.manufacturer}
                onChange={handleChange}
                placeholder="Enter manufactor"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="minStockLevel">Minimum Stock Level</Label>
              <Input
                type="text"
                name="minStockLevel"
                id="minStockLevel"
                value={formData.minStockLevel}
                onChange={handleChange}
                placeholder="Enter minimum stock level"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="maxStockLevel">Maximum Stock Level</Label>
              <Input
                type="text"
                name="maxStockLevel"
                id="maxStockLevel"
                value={formData.maxStockLevel}
                onChange={handleChange}
                placeholder="Enter maximum stock level"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="reorderPoint">Reorder Point</Label>
              <Input
                type="text"
                name="reorderPoint"
                id="reorderPoint"
                value={formData.reorderPoint}
                onChange={handleChange}
                placeholder="Enter reorder point"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="reorderQuantity">Reorder Quantity</Label>
              <Input
                type="text"
                name="reorderQuantity"
                id="reorderQuantity"
                value={formData.reorderQuantity}
                onChange={handleChange}
                placeholder="Enter reorder quantity"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col md={5}>
            <FormGroup>
              <Label for="barcode">Barcode</Label>
              <Input
                type="text"
                name="barcode"
                id="barcode"
                value={formData.barcode}
                onChange={handleChange}
                placeholder="Enter barcode no."
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="location">Location</Label>
              <Input
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter location"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input
                type="text"
                name="notes"
                id="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Enter notes"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="notes">Consumable</Label>
              <Input
                type="checkbox"
                name="consumable"
                id="notes"
                checked={formData.consumable}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary">Submit</Button>
      </Form>
      {
        // upto here
      }

      {
        //  <Row>
        //    <Col md="3" className="sidebar">
        //      <Nav vertical>
        //        <NavItem>
        //          <NavLink href="#">Dashboard</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Sales</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Inventory</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Prescriptions</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Customers</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Suppliers</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Reports</NavLink>
        //        </NavItem>
        //        <NavItem>
        //          <NavLink href="#">Settings</NavLink>
        //        </NavItem>
        //      </Nav>
        //    </Col>
        //    <Col md="9" className="content">
        //      <h2>Welcome to the Pharmacy Admin Dashboard</h2>
        //      <p>Select a menu item from the sidebar to get started.</p>
        //    </Col>
        //   </Row>
      }
    </Container>
  );
};

export default InventoryForm;
