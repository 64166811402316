import axios from "axios";
import {
  useState,
  useMemo,
  React,
  useEffect,
  Fragment,
  useRef,
  lazy,
  Suspense,
} from "react";
import { jwtDecode } from "jwt-decode";
import "../CRM/styles/style.css";
import validator from "validator";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
  Spinner,
} from "reactstrap";
import NavLeft from "../CRM/NavLeft";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getCustomerCareList,
  getDoctors,
  getCustomers,
  getPrescriptions,
  getCountries,
  getAppointments,
  deleteAppointmentHandler,
  addAppointment,
} from "../../../Data/Customer/api";
import PrintDemography from "./customerForm/printDemography";
import ReactToPrint from "react-to-print";

const CrmCLT = () => {
  // let savedToggle = false;
  const navigate = useNavigate();
  const location = useLocation();
  const authData = location.state;
  const [token] = useState(
    authData ? authData.token : JSON.parse(sessionStorage.getItem("token"))
  );
  const [user] = useState(JSON.parse(sessionStorage.getItem("username")));
  const [rights] = useState(JSON.parse(sessionStorage.getItem("rights")));
  const [fname, setFname] = useState("");
  const [sname, setSname] = useState("");
  //const [id, setId] = useState("");
  const [dob, setDOB] = useState("");
  const [contactno, setContactno] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [lastSession, setLastSession] = useState("");
  const [treatmenttype, setTreatmentType] = useState("PRP");
  const [remarks, setRemarks] = useState("");
  const [patientcoordinator, setPatientCoordinator] = useState("");
  const date = new Date();
  const utcDate = date.toISOString(); // saving pupose, always put this before date.setHours, which modifies date itself
  // const startDay = new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(); // searching purpose

  const [age, setAge] = useState("");
  const [source, setSource] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [reasonforVisit, setReasonforVisit] = useState("");
  const [dnd, setDND] = useState("");

  const [MRN, setMRN] = useState("");
  const [tempMRN, setTempMRN] = useState("");
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchData, setSearchData] = useState("");
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customerData, setCustomerData] = useState("");
  const [appointmentData, setAppointmentData] = useState("");
  const toggle = () => setModal(!modal);
  const [modalName, setModalName] = useState("");
  const [sex, setSex] = useState("male");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [otData, setOTData] = useState("");
  const [emailId, setEmailId] = useState("");
  const [occupation, setOccupation] = useState("");
  const [pastPrescription, setPastPrescription] = useState("");
  const [reactionAlergies, setReactionAlergies] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [formisValid, setFormIsValid] = useState(false);
  const [savedCustomerData, setSavedCustomerData] = useState("");
  const [editSavedData, setEditSavedData] = useState("");
  const [doctorsList, setDoctorsList] = useState("");
  const [customerHistory, setCustomerHistory] = useState("");
  const [prescriptionHistory, setPrescriptionHistory] = useState("");
  const [isLoadingAppointment, setIsLoadingAppointment] = useState(false);
  const [visitType, setVisitType] = useState("");

  const addressData =
    sessionStorage.getItem("address") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("address"))
      : "";
  let doctorID = "";
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState("");
  const [tempPatientData, setTempPatientData] = useState("");
  const [togglePastPrescript, settogglePastPrescript] = useState(false);
  const [toggleAllergy, settoggleAllergy] = useState(false);
  // Create a new Date object for the current date and time in IST
  const currentDateIST = new Date();
  const [sourceDetails, setSourceDetails] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const [datewithoutHours, setDatewithoutHours] = useState("");
  const [patientSession, setPatientSession] = useState("");
  const [patientState, setPatientState] = useState("");
  const [district, setDistrict] = useState("");
  const [category, setCategory] = useState("");
  const [isValidDate, setIsValidDate] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");
  const [advancePaymentDate, setAdvancePaymentDate] = useState("");
  const componentRef = useRef();
  const PatientHistory = lazy(() => import("./PatientHistory"));
  // Set the time components of the current date to represent the start of the day in IST
  currentDateIST.setHours(0, 0, 0, 0);

  // Get the ISO 8601 string representation of the start of the day in IST
  const startOfDayIST = new Date(currentDateIST).toISOString();

  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "";
  const [locality, setLocality] = useState(branch === "CLT" ? "Calicut" : "");

  const toggleEdit = () => {
    setModal(!modal);
    if (MRN) {
      setEditMode(true);
    }
  };

  const sourceOptions = [
    "Reference",
    "Hoardings",
    "Advertisement",
    "Word of mouth",
    "Through a friend/relavive",
    "Instagram",
    "Youtube",
    "Faceebook",
    "Twitter",
    "Whatsapp",
    "Google",
    "X",
  ];

  const [treatmentTypeOptions] = useState([
    "PRP SESSION WISE",
    "PRP 3 SESSION PKG",
    "PRP 6 SESSION PKG",
    "PRP PKG",
    "GFC SESSION WISE",
    "GFC 3 SESSION PKG",
    "GFC 6 SESSION PKG",
    "GFC PKG",
    "HAIR TRANSPLANT",
    "HAIR TRANSPLANT FRONTAL AREA",
    "HAIR TRANSPLANTATION 2000 GRAFTS",
    "HAIR TRANSPLANTATION 2500 GRAFTS",
    "HAIR TRANSPLANTATION 3000 GRAFTS",
    "HAIR TRANSPLANTATION 3500 GRAFTS",
    "HAIR TRANSPLANTATION 4000 GRAFTS",
    "HAIR TRANSPLANTATION 4000 MAXIMUM COVERAGE",
    "DERMAT CONSULTATION",
    "HAIRLOSS CONSULTATION",
    "PRP",
    "GFC",
    "BIOTIN PRP",
    "HAIR TRANSPLANT",
  ]);
  const occupationOptions = [
    "Self Employed",
    "Home Maker",
    "Student",
    "Professional",
  ];
  const titleCaseHandler = (name) => {
    // Split the name into parts
    const parts = name.split(" ");

    // Capitalize the first letter of each part
    const capitalizedParts = parts.map((part) => {
      return part.charAt(0).toUpperCase() + part.slice(1);
    });

    // Join the parts back together with a space
    return capitalizedParts.join(" ");
  };
  const formatDate = (dte) => {
    const fdate = new Date(dte);

    const ISTOffset = 330; // IST offset in minutes
    const ISTTime = new Date(fdate.getTime() + ISTOffset * 60000); // IST time
    const formattedDate = ISTTime.toISOString().split("T")[0];

    return formattedDate;
  };
  const formatInputDate = (dateString) => {
    // Format the input date to yyyy-mm-dd
    const formattedDate = dateString.replace(/[^0-9-]/g, "").slice(0, 10);
    return formattedDate;
  };

  const validateDate = (dateString) => {
    if (typeof dateString !== "string") {
      return false;
    }
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match = dateString.match(regex);

    if (!match) {
      return false; // Date string does not match the expected format
    }

    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Zero-based month
    const day = parseInt(match[3], 10);

    // Check if the date is valid
    const date = new Date(year, month, day);
    if (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    ) {
      return true; // Valid date
    }

    return false; // Invalid date
  };

  useEffect(() => {
    if (contactno.length > 9) {
      //  axios.post(
      //   'https://web.whatsapp.com/send?phone=+919886433255&text="dfdfdfdfdf"&app_absent=0'
      // );
      const contactIsValid = validator.isMobilePhone(contactno);

      // token, branch, contactno, date, navigate

      if (contactIsValid && contactno.length > 9) {
        getCustomerCareList(token, branch, contactno, "", navigate).then(
          (res) => {
            setCustomerHistory(res);
            if (res.length > 0) {
              if (!searchData && !sessionStorage.getItem("srchData")) {
                setModalName("Customer History");
                toggle();
              }
            }
          }
        );
      }
    }
  }, [contactno]);

  useEffect(() => {
    if (!countryList) {
      getCountries(token, "", navigate).then((res) => {
        setCountryList(res);
      });
    }
  }, [countryList]);

  useEffect(() => {
    const fetchDoctorData = async () => {
      if (branch && token) {
        try {
          // or const respone = await getbranchData(branch);

          getDoctors(token, branch, navigate).then((res) => {
            setDoctorsList(res);
            localStorage.setItem("doctorsData", JSON.stringify(res));
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    if (!token) {
      navigate("/");
    }
    fetchDoctorData();
  }, [branch, token]);

  useEffect(() => {
    // if (token && MRN) {
    //   setIsLoadingAppointment(true);
    //   axios
    //     .get(`${process.env.REACT_APP_CRI_API}appointments`, {
    //       params: {
    //         date: datewithoutHours,
    //         fromDate: datewithoutHours,
    //         branch: branch,
    //         mrn: MRN,
    //       },
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     .then((res) => {
    //       setIsLoadingAppointment(false);
    //
    //       setAppointmentData(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setIsLoadingAppointment(false);
    //       if (
    //         (err.response &&
    //           err.response.data &&
    //           err.response.data.err.name === "TokenExpiredError") ||
    //         err.response.data.message === "Unauthorized"
    //       ) {
    //         navigate("/");
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("srchData");
    //         sessionStorage.removeItem("prescriptionHistory");
    //         sessionStorage.removeItem("rights");
    //         navigate("/cri-crm");
    //         return err;
    //       }
    //     });
    //   const tokenSession = JSON.parse(sessionStorage.getItem("token"));
    //   if (authData && !tokenSession) {
    //     sessionStorage.setItem("token", JSON.stringify(authData.token));
    //   }
    // }
    // token,
    // selectedDate,
    // branch,
    // frmDate,
    // doctorName,
    // department,
    // navigate
    if (token) {
      const nDate = new Date();
      const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      getAppointments(
        token,
        withoutHours,
        branch,
        withoutHours,
        "",
        "",
        navigate
      )
        .then((res) => {
          setIsLoadingAppointment(false);
          // console.log(res);
          setAppointmentData(res);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingAppointment(false);
        });
    }
  }, [authData, MRN]);

  const registerTempPatient = (data) => {
    setTempPatientData(data);
    setFname(data.fname);
    setSname(data.sname);
    setContactno(data.contactno);
    setDoctorName(data.doctor);
    setReasonforVisit(data.visitReason);
    setTempMRN(data.mrn);
  };

  let postAppointmentData = {
    mrn: MRN,
    date: datewithoutHours,
    time: tempPatientData.time,
    endTime: tempPatientData.endTime,
    doctor: tempPatientData.doctor,
    createdBy: user,
    fname,
    sname,
    contactno,
    department: tempPatientData.department,
    visitType: tempPatientData.visitType,
    visitReason: tempPatientData.visitReason,
  };
  const additional = {
    visitType,
    advancePayment,
    advancePaymentDate,
  };
  const postData = {
    fname,
    sname,
    contactno,
    emailId,
    nationality,
    location: locality,
    address,
    treatmenttype,
    occupation,
    pastPrescription,
    reactionAlergies,
    remarks,
    patientcoordinator,
    doctorName,
    date: datewithoutHours,
    dob,
    sex,
    age,
    source,
    reasonforVisit,
    dnd,
    createdBy: user,
    togglePastPrescript,
    toggleAllergy,
    sourceDetails,
    patientSession,
    patientState,
    district,
    category,
    additional,
  };

  const editPostData = {
    fname,
    sname,
    contactno,
    emailId,
    nationality,
    location: locality,
    address,
    treatmenttype,
    occupation,
    pastPrescription,
    reactionAlergies,
    remarks,
    patientcoordinator,
    doctorName,
    date: datewithoutHours,
    dob,
    sex,
    mrn: MRN,
    age,
    source,
    createdBy,
    reasonforVisit,
    dnd,
    togglePastPrescript,
    toggleAllergy,
    sourceDetails,
    patientSession,
    patientState,
    district,
    category,
    additional,
  };
  const onChangeEditHandler = () => {
    if (editMode) {
      setSaveSuccess(false);
    }
  };

  const editPostHandler = async () => {
    if (saveSuccess) {
      alert("Already Saved");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CRI_API}edit-customer`,
        editPostData,
        {
          params: {
            branch: branch,
            mrn: MRN,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Edit successful");

        //   savedToggle = true;

        setSaveSuccess(true);
        //  token, branch,mrn,doctorName,date,navigate
        getCustomers(
          token,
          branch,
          MRN,
          doctorName,
          datewithoutHours,
          navigate
        ).then((res) => {
          updateSrchData(res[0]);
          sessionStorage.setItem("srchData", JSON.stringify(res[0]));
          setEditSavedData(res[0]);
        });
      } else {
        // Handle failed login
        console.log("Invalid credentials");
      }
    } catch (err) {
      if (
        (err.response.data &&
          err.response.data.err.name === "TokenExpiredError") ||
        err.response.data.message === "Unauthorized"
      ) {
        alert("Login Again to Continue");
        navigate("/");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("srchData");
        sessionStorage.removeItem("prescriptionHistory");
        sessionStorage.removeItem("rights");
        navigate("/cri-crm");
        return err;
      }
    }
  };
  //setIsValidDate
  const submitHandler = async () => {
    // sessionStorage.setItem("srchData", JSON.stringify(postData));
    if (MRN && editMode) {
      editPostHandler();
      return;
    } else if (validateDate)
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_CRI_API}add-customer`,
          postData,
          {
            params: { branch: branch },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setLoading(false);
          // Handle successful login
          setSaveSuccess(true);
          alert("Save successful");

          // setSavedCustomerData(response.data);
          setMRN(response.data.mrn);
          sessionStorage.setItem("srchData", JSON.stringify(response.data));
          //   getCustomers(token, branch, response.data.mrn, "", "", navigate).then(
          //     (res) => {
          //       console.log(res);
          //       sessionStorage.setItem("srchData", JSON.stringify(res[0]));
          //     }
          //  );
          if (tempMRN) {
            //  token, branch, datewithoutHours,_id, tempmrn
            deleteAppointmentHandler(
              token,
              branch,
              datewithoutHours,
              "",
              tempMRN,
              navigate
            );
            //token, branch, postData
            if (response.data.mrn) {
              addAppointment(
                token,
                branch,
                { ...postAppointmentData, mrn: response.data.mrn },
                navigate
              );
            }
          }
          setOTData([
            {
              fname,
              sname,
              contactno,
              address,
              treatmenttype,
              remarks,
              patientcoordinator,
              doctorName,
              date: formatDate(date),
              dob,
              mrn: response.data.mrn,
              togglePastPrescript,
              toggleAllergy,
              sourceDetails,
            },
          ]);
        } else {
          setLoading(false);
          // Handle failed login
          console.log("Invalid credentials");
        }
      } catch (err) {
        setLoading(false);
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      }
  };

  useEffect(() => {
    if (validateDate(selectedDate)) {
      const nDate = new Date(selectedDate);
      const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      setDatewithoutHours(withoutHours);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (searchData && searchData.date) {
      setSelectedDate(() => formatDate(searchData.date));
    } else {
      //   const date = new Date();
      //   const formattedDate = date.toISOString().split("T")[0];
      //   setSelectedDate(formattedDate);
      //console.log(formattedDate);
    }
  }, [searchData]);

  useEffect(() => {
    //   handleCountry();
    if (verified) {
      submitHandler();
      setVerified(false);
    }
  }, [verified]);

  const prescriptionHistoryHandler = () => {
    if (MRN) {
      getPrescriptions(token, branch, MRN, datewithoutHours, navigate).then(
        (res) => {
          //console.log(res);
          setPrescriptionHistory(res);
          if (res) {
            setModalName("Prescription History");
            toggle();
            sessionStorage.setItem("prescriptionHistory", JSON.stringify(res));
          }
        }
      );
    } else {
      alert("Please select a patient");
    }
  };

  const searchUpdateHandler = (data) => {
    setEditMode(false);
    setAppointmentData("");
    setSearchData(data);
    setFname(data.fname);
    setSname(data.sname);
    setContactno(data.contactno);
    setEmailId(data.emailId);
    setAddress(data.address);
    setNationality(data.nationality);
    setLocality(data.location);
    setTreatmentType(data.treatmenttype);
    setOccupation(data.occupation);
    setPastPrescription(data.pastPrescription);
    setReactionAlergies(data.reactionAlergies);
    setRemarks(data.remarks);
    setPatientCoordinator(data.patientcoordinator);
    setMRN(data.mrn);
    setDOB(data.dob);
    setSex(data.sex);
    setDoctorName(data.doctorName);
    setAge(data.age);
    setSource(data.source);
    setCreatedBy(data.createdBy);
    setReasonforVisit(data.reasonforVisit);
    setDND(data.dnd);
    settogglePastPrescript(data.togglePastPrescript);
    settoggleAllergy(data.toggleAllergy);
    setSourceDetails(data.sourceDetails);
    setPatientSession(data.patientSession);
    setPatientState(data.patientState);
    setDistrict(data.district);
    setCategory(data.category);
    // setSelectedDate(formatDate(data.date));
    setVisitType(data?.additional?.visitType);
    setAdvancePayment(data?.additional?.advancePayment);
    setAdvancePaymentDate(data?.additional?.advancePaymentDate);

    getPrescriptions(token, branch, data.mrn, datewithoutHours, navigate).then(
      (res) => {
        setPrescriptionHistory(res);
        if (res) {
          sessionStorage.setItem("prescriptionHistory", JSON.stringify(res));
        }
      }
    );
  };

  let srchData = "";

  const updateSrchData = (srchData) => {
    setCustomerData(srchData);
    setFname(srchData.fname);
    setSname(srchData.sname);
    setContactno(srchData.contactno);
    setEmailId(srchData.emailId);
    setAddress(srchData.address);
    setNationality(srchData.nationality);
    setLocality(srchData.location);
    setTreatmentType(srchData.treatmenttype);
    setOccupation(srchData.occupation);
    setPastPrescription(srchData.pastPrescription);
    setReactionAlergies(srchData.reactionAlergies);
    setRemarks(srchData.remarks);
    setPatientCoordinator(srchData.patientcoordinator);
    setMRN(srchData.mrn);
    setDOB(srchData.dob);
    setSex(srchData.sex);
    setDoctorName(srchData.doctorName);
    setVisitType(srchData?.additional?.visitType);
    setAdvancePayment(srchData?.additional?.advancePayment);
    setAge(srchData.age);
    setSource(srchData.source);
    setCreatedBy(srchData.createdBy);
    setReasonforVisit(srchData.reasonforVisit);
    setDND(srchData.dnd);
    settogglePastPrescript(srchData.togglePastPrescript);
    settoggleAllergy(srchData.toggleAllergy);
    setSourceDetails(srchData.sourceDetails);
    setPatientSession(srchData.patientSession);
    setPatientState(srchData.patientState);
    setDistrict(srchData.district);
    setCategory(srchData.category);
  };

  let printData = {
    addressData: addressData && addressData[0],
    date,
  };

  // updating srchData with sessionStorage if relfresh retain
  useEffect(() => {
    if (
      sessionStorage.getItem("srchData") !== null &&
      sessionStorage.getItem("srchData").length > 0
    ) {
      if (srchData !== undefined || srchData !== null) {
        try {
          srchData = JSON.parse(sessionStorage.getItem("srchData"));
        } catch (err) {
          console.log(err);
        }
        if (srchData && !editMode) {
          updateSrchData(srchData);
        }
      }
    }
  }, [sessionStorage.getItem("srchData")]);

  useEffect(() => {
    if (sessionStorage.getItem("prescriptionHistory") !== null) {
      setPrescriptionHistory(
        JSON.parse(sessionStorage.getItem("prescriptionHistory"))
      );
    }
  }, [sessionStorage.getItem("prescriptionHistory")]);
  const decode = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const isTokenExpired = (token) => {
    if (!token) {
      return true; // Token is considered expired if it's not present
    }

    const decodedToken = decode(token); // Assuming you have a function to decode JWT tokens
    if (!decodedToken || !decodedToken.exp) {
      return true; // Unable to decode token or expiration time is not present
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime; // Token is expired if expiration time is less than current time
  };

  useEffect(() => {
    const tokenEXpire = isTokenExpired(token);
    //console.log(tokenEXpire);
  }, [token]);

  const customerDataHandler = (data) => {
    setCustomerData(data);
  };

  const formatTime = (time24Hour) => {
    const [hours, minutes] = time24Hour.split(":");

    // Convert hours to 12-hour format
    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? "PM" : "AM";
    hours12 %= 12;
    hours12 = hours12 || 12; // Handle midnight (0 hours)

    // Format the time in 12-hour format
    const time12Hour = `${hours12}:${minutes} ${ampm}`;
    return time12Hour;
  };
  const validate = () => {
    if (!additional.visitType) {
      alert("Kindly select Visit Type");
      return false;
    }
    if (contactno.length < 10) {
      alert("Kindly enter 10 digit mobile no.");
      return false;
    }
    if (address.length < 3) {
      alert("Kindly enter address");
      return false;
    }
    if (fname.length < 2) {
      alert("Kindly enter Patient Name");
      return false;
    }
    if (!treatmenttype) {
      alert("Kindly enter treatment type");
      return false;
    }
    if (!date) {
      alert("Kindly enter date");
      return false;
    }
    if (dob.trim() === "" && age.trim() === "") {
      alert("Kindly enter dob/age");
      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    //setSaveSuccess(false);
    if (validate()) {
      setVerified(true);
    } else {
      setErrorMessage("Missing data, Kindly enter the details");
      setModalName("error");
      toggle();
    }
  };

  const clearForm = () => {
    setSelectedDate(formatDate(new Date()));
    setLocality("Calicut");
    setFname("");
    setSname("");
    //const [id, setId] = useState(""
    setDOB("");
    setContactno("");
    setAddress("");
    setNationality("");

    setTreatmentType("");
    setRemarks("");
    setPatientCoordinator("");
    setMRN("");
    setVerified("");
    setErrorMessage("");
    setSearchData("");
    setModal("");
    setEditMode("");
    setCustomerData("");
    setAppointmentData("");
    setModalName("");
    setSex("male");
    setSaveSuccess("");
    setEmailId("");
    setOccupation("");
    setPastPrescription("");
    setReactionAlergies("");
    setDoctorName("");
    setVisitType("");
    setAdvancePayment("");
    setAge("");
    settogglePastPrescript(false);
    settoggleAllergy(false);
    setSource("");
    setSourceDetails("");
    setPatientSession("");
    setPatientState("");
    setDistrict("");
    setCategory("");
  };

  const handleInputMouseDown = (e) => {
    // Prevent default behavior of input mousedown event
    if (MRN !== "" && !editMode) e.preventDefault();
  };

  const ModalContent = () => {
    if (modalName === "Temp ID Customers List") {
      return (
        <Fragment>
          <ModalBody>
            {appointmentData &&
              appointmentData
                .filter((item) => item.mrn.includes("Tmp"))
                .map((tmp, id) => {
                  return (
                    <Card key={id}>
                      <CardHeader
                        style={{
                          backdropFilter: "blur(10px)",
                          WebkitBackdropFilter: "blur(10px)", // For Safari
                          backgroundColor: "rgba(50, 0, 100, 0.7)", // Darker purple with opacity
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {" "}
                          {tmp.fname} {tmp.sname}
                        </span>
                        <button
                          className="ms-2 glass-button"
                          onClick={() => {
                            clearForm();
                            registerTempPatient(tmp);
                          }}
                        >
                          {" "}
                          Select{" "}
                        </button>
                      </CardHeader>
                      <CardBody>
                        <p>
                          Appointment Date:{" "}
                          {new Date(tmp.appointmentdate).toLocaleDateString()}
                        </p>
                        <p>Doctor: {tmp.doctor}</p>
                        <p>Mobile No: {tmp.contactno}</p>
                        <p>Visit Type: {tmp.visitType}</p>
                        <p> Visit Reason: {tmp.visitReason}</p>
                      </CardBody>
                    </Card>
                  );
                })}
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggle}>
              Ok
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "edit") {
      return (
        <Fragment>
          <ModalBody>
            <h6>Do you want to edit the data</h6>
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggleEdit}>
              Yes
            </button>
            <button className="glass-button" onClick={toggle}>
              No
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "error") {
      return (
        <Fragment>
          <ModalBody>
            <h6>{errorMessage}</h6>
          </ModalBody>
          <ModalFooter>
            <button className=" glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "Customer History") {
      return (
        <Fragment>
          <ModalBody>
            <h6>{modalName}</h6>
            <div>
              {customerHistory &&
                customerHistory.map((item, id) => {
                  return (
                    <Card key={id}>
                      <CardHeader className="d-flex justify-content-between">
                        <h5>{item.customerName}</h5>
                        <button
                          className=" glass-button"
                          onClick={() => {
                            setFname(item.customerName);
                            setAddress(
                              `${item.addressLine1}, ${item.addressLine2}`
                            );
                            setEmailId(item.emailid);
                            setLocality(item.location);
                            setNationality(item.country);
                            toggle();
                          }}
                        >
                          Select
                        </button>
                      </CardHeader>
                      <CardBody>
                        Address: {item.addressLine1}, {item.addressLine2} <br />
                        Location: {item.location} <br />
                        State: {item.state}, {item.country} <br />
                        Email Id: {item.emailid}
                      </CardBody>
                    </Card>
                  );
                })}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className=" glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "Prescription History") {
      return (
        <Fragment>
          <ModalBody>
            <h6>{modalName}</h6>
            <div>
              {prescriptionHistory &&
                prescriptionHistory.map((item, id) => {
                  return (
                    <Card key={id} className="mt-3">
                      <CardHeader>
                        {fname + " " + sname}
                        <div style={{ fontSize: "11px" }}>{item.mrn}</div>
                        <button
                          className="glass-button"
                          style={{
                            position: "absolute",
                            right: "3px",
                            top: "5px",
                          }}
                          onClick={() => {
                            printData = {
                              ...printData,
                              selectedCm: sessionStorage.getItem("srchData")
                                ? JSON.parse(sessionStorage.getItem("srchData"))
                                : "",
                              ...item,
                              date: item.date,
                              username: item.doctorName,
                            };
                            //console.log(printData);
                            navigate("/prnt", {
                              state: { printData },
                            });
                          }}
                          //setSelectCmPrnt
                        >
                          Print
                        </button>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <b>Date:</b>
                          <span>
                            {" "}
                            {`${new Date(item.date).getDate()} / ${
                              new Date(item.date).getMonth() + 1
                            } /  ${new Date(item.date).getFullYear()} `}{" "}
                            {new Date(item.date).toLocaleTimeString()}
                          </span>
                        </div>
                        <div>
                          <b className="me-1">Gender:</b>
                          {sex}
                        </div>
                        <div>
                          <b className="me-1">DOB:</b>
                          {dob}
                        </div>
                        <div>
                          <b className="me-1">Treatment:</b> {treatmenttype}
                        </div>
                        <div>
                          <b className="me-1">Past Prescription:</b>{" "}
                          {pastPrescription}
                        </div>
                        <div>
                          <b className="me-1">Drug Allergies</b>{" "}
                          {reactionAlergies}
                        </div>
                        <div>
                          <b className="me-1">Patient Coordinator:</b>{" "}
                          {patientcoordinator}
                        </div>
                        <div>
                          <b className="me-1">Remarks:</b> {remarks}
                        </div>
                        {
                          // Doc's prescription
                        }
                        <div>
                          <Label for="diagnosis"> Diagnosis:</Label>
                          <Input
                            type="textarea"
                            name="text"
                            id="diagnosis"
                            rows="5"
                            readOnly
                            value={item.diagnosis}
                          />
                          <Label for="prescription"> Prescriptions:</Label>
                          <Input
                            type="textarea"
                            name="text"
                            rows="5"
                            id="prescription"
                            readOnly
                            value={item.prescription}
                          />
                          <Label for="specialAdvice"> Special advice:</Label>
                          <Input
                            type="textarea"
                            name="text"
                            rows="5"
                            id="specialadvice"
                            value={item.specialAdvice}
                            readOnly
                          />
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    }
  };

  const appointment = useMemo(() => {
    const tmpCount =
      appointmentData &&
      appointmentData.filter((item) => item.mrn?.includes("Tmp")).length;

    const xCount =
      appointmentData &&
      appointmentData.filter((item) => !item.mrn === MRN).length;

    const currentPatientsAppointment =
      appointmentData && appointmentData.filter((item) => item.mrn === MRN);

    if (appointmentData) {
      return (
        <Toast className="mt-2">
          {tmpCount > 0 && (
            <ToastHeader icon="warning">
              New Patient - Total - {tmpCount}
              <button
                className="ms-1 glass-button"
                onClick={() => {
                  setModalName("Temp ID Customers List");
                  toggle();
                }}
              >
                View & Register
              </button>
            </ToastHeader>
          )}
          <ToastHeader icon="custom-toast-icon">
            <h6>Next Appointment</h6>
            {isLoadingAppointment && <Spinner color="primary"></Spinner>}
          </ToastHeader>

          {appointmentData.map((item, id) => {
            if (item.mrn?.includes("Tmp")) {
              return (
                <>
                  {
                    //  <ToastBody key={id}>
                    //    Treatment : {treatmenttype} <br />
                    //    Date: {}
                    //    {formatDate(new Date(item.appointmentdate))}
                    //    <br />
                    //    Time: {formatTime(item.time)}
                    //    <br />
                    //    Doctor Name: {item.doctor}
                    //  </ToastBody>
                  }
                </>
              );
            } else if (item.mrn === MRN) {
              return (
                <>
                  <ToastHeader icon="success" key={id}>
                    Name :{item.fname} {item.sname}
                    {isLoadingAppointment && (
                      <Spinner color="primary"></Spinner>
                    )}
                  </ToastHeader>

                  <ToastBody key={id}>
                    Treatment : {treatmenttype} <br />
                    Date: {}
                    {formatDate(new Date(item.appointmentdate))}
                    <br />
                    Time: {item.time}
                    <br />
                    Doctor Name: {item.doctor}
                  </ToastBody>
                </>
              );
            }
          })}
        </Toast>
      );
    }
  }, [appointmentData, isLoadingAppointment]);

  const fdate = new Date(selectedDate);
  const ISTOffset = 330; // IST offset in minutes
  const ISTTime = new Date(fdate.getTime() + ISTOffset * 60000); // IST time
  //const formattedDate = ISTTime.toISOString().split("T")[0];

  const rightDiv = useMemo(() => {
    return (
      <Col md="3">
        <Row>
          <Col md="12">
            <Row>
              <Col className="d-flex flex-column">
                <div className="mt-auto">
                  <div style={{ minHeight: "66vh" }}>
                    <Row>
                      <Label sm="4">Date :</Label>
                      <Col md="6">
                        <Input
                          type="date"
                          value={selectedDate}
                          readOnly={MRN !== "" && !editMode}
                          onChange={(e) => {
                            setSelectedDate(e.target.value);
                          }}
                          //    value={`${date.getDate()} / ${
                          //      date.getMonth() + 1
                          //    } /  ${date.getFullYear()} `}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Label sm="4">Location :</Label>
                      <Col md="6">
                        <Input
                          type="text"
                          value={locality}
                          readOnly={MRN !== "" && !editMode}
                          onChange={(e) => {
                            // (e.target.value);
                            setLocality(e.target.value);
                            onChangeEditHandler();
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    {appointment}
                  </div>
                </div>
              </Col>

              <div>
                <Row>
                  <Col md="3">
                    {
                      // <Button className="me-1">Save</Button>
                    }
                  </Col>
                  <Col>
                    <button
                      className="glass-button"
                      hidden // not applied now
                      onClick={() => {
                        if (otData && otData[0].mrn) {
                          navigate("/otBooking", {
                            //replace: true,
                            state: {
                              otData: otData[0],
                              customerData,

                              token,
                              branch,
                            },
                          });
                        } else {
                          //console.log(otData[0]);
                          alert("please select a patient");
                        }
                      }}
                    >
                      Book Appointment
                    </button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
        <Row></Row>
      </Col>
    );
  }, [appointment, otData, locality, editMode, selectedDate]);

  const main = useMemo(() => {
    return (
      <Container fluid>
        <Card className="mb-5 mt-2">
          <CardHeader
            style={{
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)", // For Safari
              backgroundColor: "rgba(50, 0, 100, 0.7)", // Darker purple with opacity
            }}
          >
            <Row className="d-flex justify-content-center text-white pt-2">
              <Col md={4}></Col>
              <Col md={4} style={{ textAlign: "center" }}>
                <h5>Clinic Management</h5>
              </Col>
              <Col md={4} style={{ textAlign: "right" }} className="pt-2">
                <b>User</b> {user}
              </Col>
            </Row>
          </CardHeader>
          <div className="d-flex justify-content-end pe-2 pt-2">
            <h4 className="me-5 mt-2" style={{ color: "rgba(50, 0, 100)" }}>
              {lastSession}
            </h4>
            <button
              className="me-3 glass-button"
              onClick={prescriptionHistoryHandler}
            >
              Prescription
            </button>
            <button
              className="me-3 glass-button"
              onClick={() => {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("srchData");
                sessionStorage.removeItem("prescriptionHistory");
                sessionStorage.removeItem("rights");
                navigate("/cri-crm");
              }}
              color="primary"
            >
              Log Out
            </button>
          </div>
          <CardBody>
            <Row className="d-flex justify-content-end mb-4">
              {MRN && <Label sm="1">MR No</Label>}
              {MRN && (
                <Col md="3">
                  <Input type="text" readOnly value={MRN} />
                </Col>
              )}
            </Row>
            <Row>
              <NavLeft
                searchUpdateHandler={searchUpdateHandler}
                customerDataHandler={customerDataHandler}
                customerData={customerData}
                doctorsList={doctorsList}
                token={token}
                mrn={MRN}
                branch={branch}
                rights={rights}
              />
              <Col>
                <Row>
                  <Label className="custom-col">First Name :</Label>
                  <Col md="3">
                    <Input
                      type="text"
                      value={fname}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setFname(() => titleCaseHandler(e.target.value));
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                  <Label className="custom-col">Second Name :</Label>
                  <Col md="3">
                    <Input
                      type="text"
                      value={sname}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setSname(() => titleCaseHandler(e.target.value));
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col">Contact No:</Label>
                  <Col md="3">
                    <Input
                      style={{ borderColor: isMobileValid ? "" : "red" }}
                      type="mobile"
                      value={contactno}
                      readOnly={MRN !== "" && !editMode}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          onChangeEditHandler();
                          setIsMobileValid(
                            validator.isMobilePhone(e.target.value)
                          );
                        } else {
                          setIsMobileValid(true);
                        }
                      }}
                      onChange={(e) => {
                        setContactno(e.target.value);
                        sessionStorage.removeItem("srchData");
                      }}
                    />
                  </Col>
                  <Label className="custom-col ">Email ID:</Label>
                  <Col md="4">
                    <Input
                      style={{ borderColor: isEmailValid ? "" : "red" }}
                      type="email"
                      value={emailId}
                      readOnly={MRN !== "" && !editMode}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          onChangeEditHandler();
                          setEmailValid(validator.isEmail(e.target.value));
                        } else {
                          setEmailValid(true);
                        }
                      }}
                      onChange={(e) => {
                        setEmailId(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col">Country:</Label>
                  <Col md="3">
                    <Input
                      type="text"
                      value={nationality}
                      list="country"
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setNationality(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                    <datalist id="country">
                      {countryList &&
                        countryList.map((item, index) => (
                          <option key={index} value={item.name} />
                        ))}
                    </datalist>
                  </Col>
                  <Label className="custom-col">State:</Label>
                  <Col md="3">
                    <Input
                      type="text"
                      value={patientState}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setPatientState(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                  <Row className="mt-3">
                    <Label className="custom-col">District:</Label>
                    <Col md="3">
                      <Input
                        type="text"
                        value={district}
                        readOnly={MRN !== "" && !editMode}
                        onChange={(e) => {
                          setDistrict(e.target.value);
                          onChangeEditHandler();
                        }}
                      />
                    </Col>
                  </Row>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col"> DOB:</Label>
                  <Col md="3">
                    <Input
                      type="date"
                      value={dob}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        //   console.log(e.target.value);
                        setDOB(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>{" "}
                  <Label style={{ width: "60px" }}> Age:</Label>
                  <Col md="1" className="p-0">
                    <Input
                      type="text"
                      value={age}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        //   console.log(e.target.value);
                        setAge(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                  <Col md="2" className="ps-3">
                    <Input
                      type="radio"
                      value={"male"}
                      checked={sex === "male" || false}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setSex(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "40px" }}>
                      {" "}
                      Male
                    </Label>
                  </Col>
                  <Col md="2" className="ms-0 ps-0">
                    <Input
                      type="radio"
                      value={"female"}
                      checked={sex === "female" || false}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setSex(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "60px" }}>
                      {" "}
                      Female
                    </Label>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col"> Address:</Label>
                  <Col md="8">
                    <Input
                      type="text"
                      value={address}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Label className="custom-col"> Treatment:</Label>
                  <Col md="4">
                    <Input
                      type="text"
                      list="data"
                      value={treatmenttype}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setTreatmentType(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                    <datalist id="data">
                      {treatmentTypeOptions.map((item, key) => (
                        <option key={key} value={item} />
                      ))}
                    </datalist>
                  </Col>

                  <Col md={5} className="d-flex align-items-end">
                    <Label className="me-4">Session</Label>

                    <Input
                      type="text"
                      value={patientSession}
                      onChange={(e) => {
                        setPatientSession(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col"> Category:</Label>
                  <Col md="4">
                    <Input
                      type="text"
                      value={category}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col"> Occupation:</Label>
                  <Col md="8">
                    <Input
                      type="text"
                      list="occupation"
                      value={occupation}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setOccupation(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                    <datalist id="occupation">
                      {occupationOptions.map((item, key) => (
                        <option key={key} value={item} />
                      ))}
                    </datalist>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label style={{ width: "210px" }}>
                    {" "}
                    Prescription - medicine taken in the past:
                  </Label>
                  <Col md="2" className="ps-3">
                    <Input
                      className="cutis"
                      name="yesPres"
                      type="radio"
                      value={togglePastPrescript}
                      checked={togglePastPrescript}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        settogglePastPrescript(() => !togglePastPrescript);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "40px" }}>
                      {" "}
                      Yes
                    </Label>
                  </Col>
                  <Col md="2" className="ms-0 ps-0">
                    <Input
                      name="yesPres"
                      type="radio"
                      value={togglePastPrescript}
                      checked={!togglePastPrescript}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        settogglePastPrescript(() => !togglePastPrescript);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "60px" }}>
                      {" "}
                      No
                    </Label>
                  </Col>
                  <Col md="7">
                    {togglePastPrescript && (
                      <Input
                        type="text"
                        value={pastPrescription}
                        placeholder="If Yes, Please provide details"
                        readOnly={MRN !== "" && !editMode}
                        onChange={(e) => {
                          setPastPrescription(e.target.value);
                          onChangeEditHandler();
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label style={{ width: "210px" }}>
                    {" "}
                    Any reaction or allergies to medicine in the past:
                  </Label>
                  <Col md="2" className="ps-3">
                    <Input
                      name="toggleAllergy"
                      type="radio"
                      value={toggleAllergy}
                      checked={toggleAllergy}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        settoggleAllergy(() => !toggleAllergy);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "40px" }}>
                      {" "}
                      Yes
                    </Label>
                  </Col>
                  <Col md="2" className="ms-0 ps-0">
                    <Input
                      name="toggleAllergy"
                      type="radio"
                      value={toggleAllergy}
                      checked={!toggleAllergy}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        settoggleAllergy(() => !toggleAllergy);
                        onChangeEditHandler();
                      }}
                    />
                    <Label className=" ms-3" style={{ width: "60px" }}>
                      {" "}
                      No
                    </Label>
                  </Col>
                  <Col md="7">
                    {toggleAllergy && (
                      <Input
                        type="text"
                        value={reactionAlergies}
                        placeholder="If Yes, Please provide details"
                        readOnly={MRN !== "" && !editMode}
                        onChange={(e) => {
                          setReactionAlergies(e.target.value);
                          onChangeEditHandler();
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="custom-col"> Remarks:</Label>
                  <Col md="4">
                    <Input
                      type="text"
                      value={remarks}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>

                  {
                    //   <Label style={{ width: "180px" }}> Advance Payment</Label>
                    //   <Col md={2}>
                    //     <Input
                    //       value={advancePayment}
                    //       readOnly={MRN !== "" && !editMode}
                    //       onChange={(e) => {
                    //         if (isNaN(e.target.value)) {
                    //           alert("Enter Numeric values");
                    //           return;
                    //         }
                    //         setAdvancePayment(e.target.value);
                    //       }}
                    //     />
                    //   </Col>
                  }
                </Row>
                <Row className="mt-3">
                  <Label className="me-5" style={{ width: "100px" }}>
                    {" "}
                    Source:
                  </Label>
                  <Col md="3">
                    <Input
                      type="text"
                      list="source"
                      value={source}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setSource(e.target.value);
                        onChangeEditHandler();
                      }}
                      placeholder="select or type"
                    />
                    <datalist id="source">
                      {sourceOptions.map((item, key) => (
                        <option key={key} value={item} />
                      ))}
                    </datalist>
                  </Col>
                  <Col md={5}>
                    <Input
                      value={sourceDetails}
                      type="text"
                      placeholder="Referred by or any details of source"
                      onChange={(e) => {
                        setSourceDetails(e.target.value);
                        onChangeEditHandler();
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  {
                    // <Label className="custom-col"> Patient Coordinator:</Label>
                    //  <Col md="3">
                    //    <Input
                    //      //defaultValue={"Auto for Agent Login"}
                    //      type="text"
                    //      readOnly={MRN !== "" && !editMode}
                    //      value={patientcoordinator}
                    //      onChange={(e) => {
                    //        setPatientCoordinator(e.target.value);
                    //      }}
                    //    />
                    //  </Col>
                  }
                  <Label className="custom-col"> Doctor Name:</Label>
                  <Col md="3">
                    <Input
                      type="text"
                      list="doctorlist"
                      value={doctorName}
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setDoctorName(e.target.value);
                        onChangeEditHandler();
                      }}
                      onMouseDown={handleInputMouseDown}
                    />
                    <datalist id="doctorlist">
                      {doctorsList.length &&
                        doctorsList.map((item, key) => (
                          <option key={key} value={item.doctorName} />
                        ))}
                    </datalist>
                  </Col>
                  <Label style={{ width: "110px" }}> Visit Type:</Label>
                  <Col md={3}>
                    <Input
                      type="select"
                      value={visitType}
                      disabled={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setVisitType(e.target.value);
                        onChangeEditHandler();
                      }}
                    >
                      <option value="">Select Visit Type</option>
                      <option value="First Visit">First Visit</option>
                      <option value="Follow Up">Follow Up</option>
                      <option value="Re-Visit">Re-Visit</option>
                    </Input>
                  </Col>
                </Row>
                {!MRN && !loading && (
                  <button
                    className="mt-4 glass-button me-2"
                    onClick={() => {
                      if (postData === savedCustomerData) {
                        alert("Already saved data");
                      }
                      handleSubmit();
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                )}

                {loading && <Spinner color="primary" type="grow"></Spinner>}
                <ReactToPrint
                  // onBeforePrint={handlePrintButtonClick}
                  trigger={() => (
                    <button className="glass-button">Print</button>
                  )}
                  content={() => componentRef.current}
                />
                {editMode && MRN && (
                  <>
                    <button
                      className="mt-4 glass-button ms-2"
                      onClick={() => {
                        if (editPostData === editSavedData) {
                          alert("Already saved");
                          return;
                        }
                        handleSubmit();
                      }}
                    >
                      {" "}
                      Update
                    </button>
                    <button
                      className="mt-4 ms-5 glass-button"
                      onClick={clearForm}
                    >
                      New Registration
                    </button>
                  </>
                )}
                {!editMode && MRN && (
                  <>
                    <button
                      className="mt-4 glass-button ms-2"
                      onClick={() => {
                        setModalName("edit");
                        toggle();
                      }}
                    >
                      {" "}
                      Edit
                    </button>
                    <button
                      className="mt-4 ms-5  glass-button me-2"
                      onClick={clearForm}
                    >
                      New Registration
                    </button>
                    <Suspense fallback={<div>Loading Patient History...</div>}>
                      <PatientHistory
                        searchData={searchData}
                        setLastSession={setLastSession}
                      />
                    </Suspense>
                  </>
                )}
              </Col>
              {rightDiv}
            </Row>
          </CardBody>
        </Card>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalContent />
        </Modal>

        <div style={{ display: "none" }}>
          <PrintDemography
            ref={componentRef}
            data={postData}
            date={date}
            address={addressData && addressData[0].address}
            branch={branch}
          />
        </div>
      </Container>
    );
  }, [handleSubmit]);

  return main;
};

export default CrmCLT;
