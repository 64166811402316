import { useEffect, useState, React, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Input, Label, Row, Table } from "reactstrap";
import ci from "../../../../../../images/cutis_new.jpeg";
import "../../../../CRM/styles/style.css";
import ReactToPrint from "react-to-print";
import axios from "axios";
import {
  getProcedures,
  getbranchData,
  searchCustomers,
  searchInvoices,
} from "../../../../../../Data/Customer/api";
import PrintBill from "./PrintBill";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { getInventoryItems } from "../../../../../../redux/inventory";
import PurchaseForm from "../PurchaseForm";
import PurchaseEntry from "./PurchaseEntry";

const Return = ({ returnData, mode }) => {
  const auth = useSelector((state) => state.authentication);
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  const dispatch = useDispatch();
  const { userData } = auth;
  let savedToggle = false;
  const [showModal, setShowModal] = useState(false);
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const date = new Date();
  const [mrn, setMrn] = useState(data && data.otData.mrn);
  const dateNew = new Date();
  const datewithoutHours = new Date(dateNew.setHours(0, 0, 0, 0)).toISOString();
  const time = date.getTime();
  const branch = data ? data.branch : userData.branch;
  const [payment_mode, setPayment_mode] = useState("cash");
  const [amount_paid, setAmountPaid] = useState("");

  const [printVisible, setPrintVisible] = useState(false);
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [netAmountPay, setNetAmountPay] = useState("");
  const total_amount = total;
  const [discount, setDiscount] = useState("");
  const [receivedAmt, setReceivedAmt] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");
  const [searchResult, setSearchResult] = useState("");

  const [totalQTY, setTotalQTY] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const invoice_sheet = returnData.invoice;
  const [invoiceSheetReturn, setInvoiceSheetReturn] = useState(
    returnData.invoice || []
  );

  useEffect(() => {
    if (returnData.invoice && invoiceSheetReturn.length < 1) {
      setInvoiceSheetReturn(returnData.invoice);

      console.log(returnData);
    }
  }, [returnData.invoice]);
  useEffect(() => {
    if (userData.token) {
      dispatch(
        getInventoryItems({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  useEffect(() => {
    if (receivedAmt) {
      setBalanceAmount(() =>
        (parseFloat(netAmountPay) - parseFloat(receivedAmt)).toFixed(2)
      );
    }
  }, [receivedAmt]);

  const clearForm = () => {};

  useEffect(() => {
    if (tax || total || discount) {
      // const taxTemp = ((Number(total)) / 100).toFixed(2);
      // const rowTotal = (parseFloat(taxTemp) + parseFloat(total)).toFixed(2);
      const netPay = (parseFloat(total) - parseFloat(discount)).toFixed(2);
      // console.log(taxTemp);
      setNetAmountPay(() =>
        !isNaN(netPay) ? netPay : parseFloat(total).toFixed(2)
      );
    }
  }, [tax, total, discount]);

  //useEffect(() => {}, []);

  const updateInventory = (productId, qty) => {
    const postDataInventory = {
      quantity: qty,
    };
    axios
      .post(
        `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
        postDataInventory,
        {
          params: {
            branch: branch,
            productId: productId,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("Inventory Updated");
        }
      })
      .catch((err) => console.log(err));
  };

  const stockHandler = () => {
    const consolidatedQuantities = {};

    // Consolidate quantities for the same productId
    invoiceSheetReturn.forEach((row, rowIndex) => {
      const { productId, qty = 1, stockQty } = row[`Row${rowIndex + 1}`];
      if (productId) {
        consolidatedQuantities[productId] = {
          totalQty:
            Number(consolidatedQuantities[productId]?.totalQty || 0) +
            Number(qty),
          stockQty: stockQty,
        };
        // console.log(consolidatedQuantities);
      }
    });

    // Prepare promises for updating inventory
    const promises = Object.entries(consolidatedQuantities).map(
      ([productId, { totalQty, stockQty }]) => {
        const updtQty = Number(stockQty) + Number(totalQty);
        return updateInventory(productId, updtQty);
      }
    );

    // Execute promises and handle results
    Promise.all(promises)
      .then(() => {
        // console.log("All inventory updated successfully");
        // Perform any other actions (e.g., submit the invoice)
      })
      .catch((error) => {
        console.error("Error updating inventory:", error);
      });
  };
  const submitHandler = () => {
    stockHandler();
  };
  const calculateTotal = () => {
    const amountValues = invoiceSheetReturn
      .map((row, index) => row[`Row${index + 1}`].amount)
      .filter((i) => parseFloat(i));

    const qtyValues = invoiceSheetReturn
      .map((row, index) => row[`Row${index + 1}`].qty)
      .filter((i) => parseFloat(i));

    const taxValues = invoiceSheetReturn
      .map((row, index) => row[`Row${index + 1}`].tax)
      .filter((i) => parseFloat(i));

    const taxableList = invoiceSheetReturn
      .filter((row, index) => row[`Row${index + 1}`].tax)
      .map((row) => {
        const key = Object.keys(row)[0];
        // console.log(key, row[key]);
        return row[key];
        // return parseFloat(row[key].rate) * parseFloat(row[key].qty);
      });
    //setTaxableData(taxableList);
    const totalTempTax = taxValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalTax(totalTempTax.toFixed(2));

    const totalTempQty = qtyValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalQTY(totalTempQty);

    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const totalTempAmt = amountValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);
    //console.log(total);
    setTotal(parseFloat(totalTempAmt).toFixed(2));
    //  return total;
  };

  useEffect(() => {
    if (invoiceSheetReturn) calculateTotal();

    console.log(invoiceSheetReturn);
  }, [data, invoiceSheetReturn]);
  const handleInputProcedure = () => {
    console.log(" update qty");
  };
  const handleInputChange = (e, rowIndex, colName, row) => {
    if (!row[`Row${rowIndex + 1}`].item) {
      alert("Please select item");
      return;
    }
    const { name, value } = e.target;
    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });

    if (colName === "tax") {
      const qty = row[`Row${rowIndex + 1}`].qty || 1;

      // const percent = value === null || value === "" ? 0 : parseFloat(value);
      const taxValue = parseFloat(value);

      // consider sgst also same

      const rate = parseFloat(row[`Row${rowIndex + 1}`].rate);
      const totalRate = rate * Number(qty);
      // for re calc for total rate percent or tax per goes high
      const taxInputper = (taxValue / totalRate) * 100;
      const cgst = (taxInputper / 2).toFixed(2);
      const sgst = (taxInputper / 2).toFixed(2);

      const rowTotal = parseFloat(totalRate).toFixed(2);

      setInvoiceSheetReturn((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [`Row${rowIndex + 1}`]: {
                ...row[`Row${rowIndex + 1}`],
                [colName]: value,
                cgst,
                sgst,
                amount: !isNaN(rowTotal)
                  ? Math.round(rowTotal)
                  : parseFloat(row[`Row${rowIndex + 1}`].rate) *
                    parseFloat(qty),
              },
            };
          }
          return row;
        });
      });
      //  setInputValues((prevValues) => ({
      //    ...prevValues,
      //    [name]: value,
      //  }));
      return;
    }
    if (colName === "rate") {
      // for excluding tax calc for consultation

      const procedureTemp = row[`Row${rowIndex + 1}`].item;
      const word = "consult";
      let taxToggle = true;
      if (procedureTemp.toLowerCase().includes(word.toLowerCase())) {
        taxToggle = false;
      } else {
        taxToggle = true;
      }
      //cal tax then total
      if (row[`Row${rowIndex + 1}`].qty) {
        const qty = row[`Row${rowIndex + 1}`].qty;
        const gst = row[`Row${rowIndex + 1}`].gst;
        const taxCal = ((parseFloat(value) * gst) / 100).toFixed(2);
        const taxTemp = taxToggle ? taxCal : 0;
        const taxtotal = (parseFloat(taxTemp) * parseFloat(qty)).toFixed(2);

        //  const itemsTotal = parseFloat(value) * parseFloat(qty);

        //  const rowTotal = parseFloat(itemsTotal).toFixed(2);
        const rowTotal = (
          parseFloat(value) * parseFloat(qty) +
          parseFloat(taxtotal)
        ).toFixed(2);

        // print bill cgst sgst
        setInvoiceSheetReturn((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  tax: taxToggle ? taxtotal : "",
                  amount: !isNaN(rowTotal) ? Math.round(rowTotal) : "",
                },
              };
            }
            return row;
          });
        });
        return;
      }
      const taxTemp = (((Number(value) / 1.18) * 18) / 100).toFixed(2);

      const rowTotal = (parseFloat(value) * 1).toFixed(2);

      setInvoiceSheetReturn((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [`Row${rowIndex + 1}`]: {
                ...row[`Row${rowIndex + 1}`],
                [colName]: value,
                tax: taxTemp,
                amount: rowTotal,
              },
            };
          }
          return row;
        });
      });
    }

    if (colName === "amount") {
      if (
        row[`Row${rowIndex + 1}`].productId ||
        row[`Row${rowIndex + 1}`].item
      ) {
        const quantity = row[`Row${rowIndex + 1}`].qty;
        const gst = row[`Row${rowIndex + 1}`].gst;

        function calculateRate(amount, quantity, taxPercentage) {
          const totalAmountWithoutTax = amount / (1 + taxPercentage / 100);
          return (totalAmountWithoutTax / quantity).toFixed(2);
        }

        function calculateTaxAmount(rate, quantity, taxPercentage) {
          const amountWithoutTax = rate * quantity;
          const taxAmount = amountWithoutTax * (taxPercentage / 100);
          return taxAmount.toFixed(2);
        }

        let newRate = calculateRate(value, quantity, gst);

        let newTaxAmount = calculateTaxAmount(newRate, quantity, gst);

        let taxToggle = true;
        if (row[`Row${rowIndex + 1}`].rate) {
          const word = "consult";

          if (
            row[`Row${rowIndex + 1}`].item
              .toLowerCase()
              .includes(word.toLowerCase())
          ) {
            taxToggle = false;
          } else {
            taxToggle = true;
          }
        }
        setInvoiceSheetReturn((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  unitPrice: taxToggle ? newRate : value,
                  tax: taxToggle ? newTaxAmount : "",
                },
              };
            }
            return row;
          });
        });
      } else {
        setInvoiceSheetReturn((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  unitPrice: value,
                },
              };
            }
            return row;
          });
        });
      }
    }

    // Convert null or empty string to 0
  };

  const qtyHandler = (e, index, colName, row) => {
    const value = e.target.value;

    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });
    if (value !== "-" && (decimal || num || value === "")) {
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      //   const taxTemp = parseFloat(row[`Row${index + 1}`].tax);
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      handleInputChange(e, index, colName, row);

      if (row[`Row${index + 1}`].unitPrice) {
        const word = "consult";
        let taxToggle = true;
        if (
          row[`Row${index + 1}`].item.toLowerCase().includes(word.toLowerCase())
        ) {
          taxToggle = false;
        } else {
          taxToggle = true;
        }
        const taxHandler = () => {
          //  const enteredTaxAmt = row[`Row${index + 1}`].tax;
          //  if (enteredTaxAmt && parseFloat(enteredTaxAmt) > 0) {
          //    console.log(enteredTaxAmt);
          //    return (
          //      parseFloat(row[`Row${index + 1}`].cgst) +
          //      parseFloat(row[`Row${index + 1}`].sgst)
          //    ).toFixed(2);
          //  }
          const gst = parseFloat(row[`Row${index + 1}`].gst);
          return (
            (parseFloat(row[`Row${index + 1}`].unitPrice) * gst) /
            100
          ).toFixed(2);
        };
        const taxCal = taxHandler();
        //  console.log(taxCal);
        const taxTemp = taxToggle ? taxCal : 0;
        const taxtotal = (parseFloat(taxTemp) * parseFloat(value)).toFixed(2);

        const rowTotal = (
          parseFloat(row[`Row${index + 1}`].unitPrice) *
            parseFloat(e.target.value) +
          parseFloat(taxtotal)
        ).toFixed(2);

        //console.log(rowTotal);
        // direct update
        //   row[`Row${index + 1}`].qty
        setInvoiceSheetReturn((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  amount: !isNaN(rowTotal) ? Math.round(rowTotal) : "",
                  quantity: value,
                  tax: taxtotal,
                },
              };
            }
            return row;
          });
        });
      } else {
        setInvoiceSheetReturn((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  qty: parseFloat(e.target.value),
                },
              };
            }
            return row;
          });
        });
      }
    } else {
      alert("Enter Numeric Value");
    }
  };

  return (
    <PurchaseEntry
      returnData={returnData}
      mode={mode}
      setShowModal={setShowModal}
    />
  );
};

export default Return;
