import axios from "axios";
import React, { useEffect, useState } from "react";
import { CornerDownLeft, Edit, Link, Search, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import {
  getInventorySupplier,
  getInventoryPurchase,
} from "../../../../../redux/inventory";
import { useNavigate } from "react-router-dom";
import PurchaseForm from "./PurchaseForm";
import PaginatedList from "../PaginatedList";

const Purchase = () => {
  const itemsList = useSelector((state) => state.itemsList);
  const dispatch = useDispatch();
  const { supplierData, purchaseData, itemsData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;

  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [modalItems, setModalItems] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [popoverData, setPopoverData] = useState("");
  const toggle = () => setModal(!modal);
  const toggleModalItems = () => setModalItems(!modalItems);
  const [mode, setMode] = useState("");
  const [purchasePaginatedList, setPurchasePaginatedList] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const editSupplierHandler = (Data, toggle, type) => {
    setMode(type);
    setFormData(Data);
    toggle();
  };

  const deleteHandler = (item, itemId) => {
    const slno = prompt("Please enter the SL No. to confirm delete");

    if (slno && Number(slno.trim()) === itemId) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-purchase`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) {
            dispatch(
              getInventoryPurchase({
                token: userData.token,
                branch: userData.branch,
              })
            );
          }
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (slno) {
      alert("Contact No. not matching");
    }
  };
  const supplierNameHandler = (supplierId) => {
    const supplier = supplierData.find(
      (item) => item.supplierId === supplierId
    );
    if (supplier) return supplier.supplierName;
    return null;
  };

  return (
    <Container fluid>
      <Row>
        {
          <Col md={2} className="pt-4">
            <button
              onClick={() => {
                setFormData("");
                toggle();
                setMode("");
              }}
            >
              {" "}
              Purchase Entry
            </button>
          </Col>
        }
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search supplier Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
      </Row>
      <>
        <PaginatedList
          // setPaginatedList={setPaginatedList}
          //   searchInput={searchInput}
          setShowSpinner={setShowSpinner}
          //    endPoint="invoice-page"
          endPoint2="purchase-page"
          // limit={100}
          setPurchasePaginatedList={setPurchasePaginatedList}
          purchaseLimit={100}
        />
        {!purchasePaginatedList.length && "No Data - "}
        {purchasePaginatedList && purchasePaginatedList.length && (
          <Table responsive striped>
            <thead>
              <tr className="table-secondary">
                <th style={{ width: "70px" }}>Sl No.</th>
                <th style={{ width: "20%" }}>Supplier Name</th>
                <th>Invoice Date</th>
                <th>Invoice No</th>
                <th>Amount Paid</th>
                <th>Payment Mode</th>
                <th>Items</th>
                <th>Return</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {purchasePaginatedList
                .filter(
                  (item) =>
                    !searchInput ||
                    (item.supplier &&
                      supplierNameHandler(item.supplier?.supplierId)
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()))
                )
                .map((item, id) => {
                  return (
                    <tr key={id}>
                      <td style={{ textAlign: "center" }}>{id + 1}</td>
                      <td style={{ width: "200px" }}>
                        {supplierNameHandler(item.supplier?.supplierId)}{" "}
                      </td>
                      <td>
                        {new Date(
                          item.paymentDetails.invoice_date
                        ).toLocaleDateString()}
                      </td>
                      <td>{item.paymentDetails.invoice_id}</td>
                      <td>{item.paymentDetails.amount_paid}</td>
                      <td>{item.paymentDetails.payment_mode}</td>
                      <td>
                        <Link
                          style={{ cursor: "pointer" }}
                          id="invoiceItems"
                          type="button"
                          onClick={() => {
                            setPopoverData(item.invoice);
                          }}
                        />
                      </td>
                      <td>
                        <CornerDownLeft
                          color="blue"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            editSupplierHandler(item, toggle, "Return Mode");
                          }}
                        />
                      </td>
                      <td>
                        {(userData.rights === "Admin" ||
                          userData.rights === "Manager" ||
                          userData.rights === "Accounts") && (
                          <Edit
                            color="green"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              editSupplierHandler(item, toggle, "Edit Mode");
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {" "}
                        {(userData.rights === "Admin" ||
                          userData.rights === "Manager" ||
                          userData.rights === "Accounts") && (
                          <Trash2
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item, id + 1)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                  //    return (

                  //              <tr>
                  //                <td style={{ width: "30px" }}>{id + 1}</td>
                  //                <td style={{ width: "200px" }}>
                  //                  {supplierNameHandler(item.supplier?.supplierId)}{" "}
                  //                </td>
                  //                {
                  //                  //<td>{item.brandName}</td>
                  //                }
                  //                <td style={{ width: "150px" }}></td>
                  //                <td style={{ width: "300px" }}></td>
                  //                <td
                  //                  style={{ width: "200px", textAlign: "center" }}
                  //                ></td>
                  //                <td
                  //                  style={{ width: "200px", textAlign: "center" }}
                  //                >
                  //                  <div style={{ background: "transparent" }}>
                  //                    {
                  //                      // <Edit
                  //                      //  color="green"
                  //                      //  style={{ cursor: "pointer" }}
                  //                      //  onClick={() => {
                  //                      //    // console.log(item);
                  //                      //    editSupplierHandler(item, toggle);
                  //                      //  }}
                  //                      // />
                  //                      //    <Trash2
                  //                      //      color="red"
                  //                      //      style={{ cursor: "pointer" }}
                  //                      //      onClick={() => deleteHandler(item, id + 1)}
                  //                      //    />
                  //                    }
                  //                  </div>
                  //                </td>
                  //              </tr>
                  //            </tbody>
                  //          </Table>
                })}
            </tbody>
          </Table>
        )}
      </>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>{mode}</ModalHeader>
        <ModalBody>
          <PurchaseForm
            editFormData={formData}
            togglePurchaseEntry={toggle}
            mode={mode}
          />
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>

      {purchasePaginatedList && purchasePaginatedList.length && (
        <UncontrolledPopover
          placement="bottom"
          target="invoiceItems"
          trigger="legacy"
          // hide={() => setPopoverData("")}
        >
          <PopoverHeader>Invoice Items</PopoverHeader>
          <PopoverBody>
            <Table>
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {popoverData &&
                  Object.values(popoverData)
                    .filter((row) => Object.values(row)[0].item !== "")
                    .map((row, ind) => (
                      <tr key={ind}>
                        <td style={{ minWidth: "10px" }}>{ind + 1}</td>
                        <td>{Object.values(row)[0].item}</td>
                        <td>{Object.values(row)[0].unitPrice}</td>
                        <td>{Object.values(row)[0].quantity}</td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </Container>
  );
};

export default Purchase;
